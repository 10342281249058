@font-face {
    font-family: 'Merriweather';
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.4px;
    src: url('./fonts/Merriweather-Regular.woff2') format('woff2'),
      url('./fonts/Merriweather-Regular.ttf') format('ttf'),
      url('./fonts/Merriweather-Regular.eot') format('eot'),
      url('./fonts/Merriweather-Regular.otf') format('otf'),
      url('./fonts/Merriweather-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.4px;
    src: url('./fonts/NotoSans-Medium.woff2') format('woff2'),
      url('./fonts/NotoSans-Medium.ttf') format('ttf'), url('./fonts/NotoSans-Medium.eot') format('eot'),
      url('./fonts/NotoSans-Medium.otf') format('otf'), url('./fonts/NotoSans-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Lato';
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.4px;
    src: url('./fonts/Lato-Bold.woff2') format('woff2'), url('./fonts/Lato-Regular.ttf') format('ttf'),
      url('./fonts/Lato-Regular.eot') format('eot'), url('./fonts/Lato-Regular.otf') format('otf'),
      url('./fonts/Lato-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'Raleway';
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.4px;
    src: url('./fonts/Raleway-Medium.woff2') format('woff2'),
      url('./fonts/Raleway-Medium.ttf') format('ttf'), url('./fonts/Raleway-Medium.eot') format('eot'),
      url('./fonts/Raleway-Medium.otf') format('otf'), url('./fonts/Raleway-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.4px;
    src: url('./fonts/Poppins-Medium.woff2') format('woff2'),
      url('./fonts/Poppins-Medium.ttf') format('ttf'), url('./fonts/Poppins-Medium.eot') format('eot'),
      url('./fonts/Poppins-Medium.otf') format('otf'), url('./fonts/Poppins-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Oswald';
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.4px;
    src: url('./fonts/Oswald-Medium.woff2') format('woff2'),
      url('./fonts/Oswald-Medium.ttf') format('ttf'), url('./fonts/Oswald-Medium.eot') format('eot'),
      url('./fonts/Oswald-Medium.otf') format('otf'), url('./fonts/Oswald-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Open Sans';
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.4px;
    src: url('./fonts/OpenSans-Medium.woff2') format('woff2'),
      url('./fonts/OpenSans-Medium.ttf') format('ttf'), url('./fonts/OpenSans-Medium.eot') format('eot'),
      url('./fonts/OpenSans-Medium.otf') format('otf'), url('./fonts/OpenSans-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Inter';
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.4px;
    src: url('./fonts/Inter-Medium.woff2') format('woff2'), url('./fonts/Inter-Medium.ttf') format('ttf'),
      url('./fonts/Inter-Medium.eot') format('eot'), url('./fonts/Inter-Medium.otf') format('otf'),
      url('./fonts/Inter-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.4px;
    src: url('./fonts/Rubik-Medium.woff2') format('woff2'), url('./fonts/Rubik-Medium.ttf') format('ttf'),
      url('./fonts/Rubik-Medium.eot') format('eot'), url('./fonts/Rubik-Medium.otf') format('otf'),
      url('./fonts/Rubik-Medium.woff') format('woff');
  }
  