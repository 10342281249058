.language-container {
    max-height: 400px; /* Adjust as needed */
    overflow-y: auto;
    position: relative;
  }
  
  .language-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .more-languages-indicator {
    position: sticky;
    bottom: 0;
    background-color: white;
    text-align: center;
    padding: 10px;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .more-languages-indicator span {
    font-size: 1rem;
    color: #007bff;
  }
  .desktopView .screenHeight {
    height: 100dvh;
  }
  body.voncierge .vonciergeHeaderIcon,
  body.changi .changiHeaderIcon {
    display: flex;
  }
  body.voncierge .changiHeaderIcon,
  body.changi .vonciergeHeaderIcon {
    display: none;
  }
@media(max-width: 1439px) {
  .firstScreen.desktopView .screenWidth {
    margin: 0 50px;
  }
  .desktopView .middleSection h1 {
    width: auto;
  }
}
@media(max-width: 1368px) {
  .firstScreen.desktopView .screenWidth h1 {
    font-size: 48px;
  }
  .firstScreen.desktopView .screenWidth h2 {
    font-size: 34px;
  }
}
  @media(max-width: 1279px) {
    .firstScreen.desktopView .screenWidth .middleSection h1 {
      font-size: 30px;
    }
    .firstScreen.desktopView .screenWidth h2 {
      font-size: 24px;
    }

    .firstScreen.desktopView .screenWidth h1, .firstScreen.desktopView .screenWidth h2 {
      line-height: 34px;
    }
    .firstScreen.desktopView h6 {
      width: auto;
    }
  }
  @media(max-width: 830px) {
    .homeButton.headerBtn {
      right: 52px;
      left: auto;
    }
    .header .headerBackBtn {
        left: 52px;
    }
  }
@media(max-width: 639px) {
  .homeIcon svg {
    width: 50px;
    height: 50px;
  }
  .heading svg {
    width: 40px;
    height: 40px;
  }
}
@media(max-width: 575px) {
  body .languageName {
    padding: 0 32px;
  }
}
@media(max-width: 400px) {
  body .queueImg {
    width: 280px;
  }
  .select-language  h2, .categoryPage .heading span {
    font-size: 16px;
  }
}