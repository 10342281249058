body.Tabsquare .firstScreen .logo {
    height: 30px;
    width: auto;
}
body.Tabsquare .header .logo  {
    width: 200px;
}
body.Tabsquare .header .companyLogo {
    background-size: contain;
}

body.voncierge .firstScreen .logoSection {
    max-width: 1280px;
    margin: auto;
}
body.Nparks .logo .companyLogo {
    background-size: 78px; 
  }
@media (max-width: 1439px) {
    body.voncierge .firstScreen .logoSection {
        max-width: 1190px;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1279px) {
    body.voncierge .firstScreen .logoSection {
        max-width: 950px;
    }
}
@media(max-width: 1024px) {
    body.voncierge .logoAlign {
        justify-content: center;
    }
}
@media(max-width: 639px) {

body.Nparks .logo .companyLogo {
    background-size: 48px; 
  }
}