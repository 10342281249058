:root {
  --primaryColor : #7D6A55;
  --white : #fff;
  --redColor : #EE3424;
  --changiHeader : #491F53;
  --fushia : #EA4498
}

body, pre {
  font-family: 'Lato', sans-serif;
  overflow-x: hidden !important;
}
body {
  background-color: var(--white);
}
* {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body > iframe {
  display: none;
}
body .feedback .thankYou {
  height: calc(100vh - 80px);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.videoPage {
  background-color: #1e1e1e;
}
div#videoSection.showChat {
  height: auto;
}
.chat-box  {
  height: calc(100dvh - 120px);
}
.callScreen {
  height: calc(100dvh - 120px);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.connectStyle {
  min-height: calc(100vh - 156px);
}
.screenStyle {
  height: 100vh
}
.transform-x-center {
  transform: translateX(-50%);
}
.custom-radio:before {
  transform: translateY(-50%);
  transition: border-color 400ms ease;
}
.custom-radio:after {
  transform: translateY(-50%) scale(0);
  transition: transform 400ms ease;
}
input[type="radio"]:checked + label::before {
  border-color: var(--primary-color);
}

input[type="radio"]:checked + label::after {
  transform: translateY(-50%) scale(0.55);
}
.modal-content {
  opacity: 0;
}
.modal-open .modal-content {
  opacity: 1;
}
/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  background: transparent;
  color: #333;
}
.queueNo {
  transform: translateX(-50%);
  
}
.message-list {
  height: calc(100vh - 255px);
}
.btnEffect {
  box-shadow: unset;
}
.btnEffect:hover {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.sender-section,
.receiver-section {
  flex: 1;
  padding: 10px;
}

.message-section {
  margin-bottom: 20px;
}
.date {
  font-size: 0.8em;
  color: #888;
}

.send-message-form {
  display: flex;
  margin-top: 10px;
}
.send-message-form button {
  padding: 5px 10px;
  margin-left: 5px;
  cursor: pointer;
}
.thankYou {
  height: calc(100dvh - 100px);
}
.pageStyle {
  min-height: calc(100vh - 156px);
}
/* .language {
  min-height: calc(100vh - 150px);
} */
.screensaver,
.firstScreen,
.select-language,
.customTransition {
  opacity: 0;
  animation: fadeIn 0.2s ease-in-out forwards;
}
.customScrollbar::-webkit-scrollbar-track {
	background-color: #ffebf5;
  border-radius: 4px;
}

.customScrollbar::-webkit-scrollbar {
	width: 6px;
	background-color: #ffebf5;
}

.customScrollbar::-webkit-scrollbar-thumb {
	background-color: #eda6ca;
  border-radius: 4px;
}
.pipWindow {
  background-color: #242d36;
  z-index: 100;
  position: fixed;
  font-size: 14px;
  font-weight: 200;
  line-height: 1.5;
  color: rgb(248, 248, 249);
  box-sizing: content-box;
  box-shadow: 1px 3px 3px 0 rgb(0 0 0 / 20%), 1px 3px 15px 2px rgb(0 0 0 / 20%);
  border-radius: 0.28571429rem;
}

.pipWindow:hover {
  cursor: grab;
}

.pipWindow:active {
  cursor: grabbing;
  outline: -webkit-focus-ring-color auto 1px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 1px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.videoOn.videoRemote {
  transform: unset;
}
.videoOn, .advisorImg, .customTransform {
  transform: translate(-50%, -50%);
}
.videoOn.isScreenShare {
  transform: unset;
}
body.changi .firstScreen, body.changi .errorPage , body.changi .bgColorClass {
  background: linear-gradient(180deg, rgba(123,36,129,1) 0%, rgba(208,60,146,1) 30%, rgba(251,214,166,1) 100%) !important;
}
.bgwhite {
  transform: translateX(-50%) scale(0.8);
}
.screenImg {
  background-size: 50vh;
}
body .noAdvisorImg {
  width: auto;
  height: calc(100dvh - 200px);
}
body .noAdvisorImg > div {
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.prompt {
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.8);
}
#loading-bar-spinner.spinner {
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}
.defaultBtn #loading-bar-spinner.spinner .spinner-icon  {
  width: 20px;
  height: 20px;
  border-top-color:  #eee !important;
  border-left-color: #eee !important;
}
#loading-bar-spinner.spinner .spinner-icon {
  width: 25px;
  height: 25px;
  border:  solid 4px transparent;
  border-top-color:  #666 !important;
  border-left-color: #666 !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
0%   { transform: rotate(0deg);   transform: rotate(0deg); }
100% { transform: rotate(360deg); transform: rotate(360deg); }
}
.chat-wrapper {
  position: fixed;
  bottom: 95px;
  /* left: 0; */
  right: 24px;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
}
.chatShow {
  transform: translateY(0%);
}

.hide-chat {
  transform: translateY(133%);
}

.videoCallPageWithChat {
  transition: height 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
}
.bottomDiv{
  width: calc(100vw - 60px);

}
/* CSS */
.videoCallPage {
  transition: opacity 0.5s ease-in-out;
  opacity: 1; /* Initial state */
}

.chat-box {
  transition: height 0.3s ease-in-out;
}
#videoSection {
  width: 100%;
  border-radius: 15px;
}
.videoCallPage.hidden {
  opacity: 0; /* Hidden state */
}
.chat-box.chatBox {
  height: 0 !important;
}
.chat-box.chatBox .message-list {
  height: 0;
}
.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}
.btnDiv{
  box-shadow: 0px -3px 30px 0px #0000001A;
}
.borderBottom {
  background: linear-gradient(90deg, rgba(204, 204, 204, 0.50) 0%, #9E9E9E 51.5%, rgba(204, 204, 204, 0.50) 100%);
  height: 1px;
}
.crossIcon {
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.30);
}
/* #root + div {
  transform: translateY(-50%);
  top: 50% !important;
  left: auto !important;
  right: 15%;
}
#root + div > div:nth-child(3) {
  display: none;
} */
.bottomArrow {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, #FFF 99.16%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.landscape .arrowIcon {
  display: block;
  margin: auto;
}
.landscape .bottomSection {
  bottom: 0;
  background-size: 100%;
}
.landscape .bottomSection .arrowIconBgImg > div {
  background-color: transparent;
}
.landscape .advisorName {
  display: none;
}
.landscape .bottomSection .arrowIconBgImg > div {
  margin-bottom: 0;
}
.landscape .bottomIcons {
  justify-content: center;
}
.landscape .bottomIcons > div {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  padding: 8px;
}
.landscape .arrowIconBgImg {
  position: relative;
  width: 100%;
}
.chatBox {
  height : calc(100vh - 110px)
}
body.changi .termsBgImg {
  background-color: rgb(108,33,127);
  background-image: linear-gradient(90deg, rgba(108,33,127,1) 13%, rgba(234,68,152,1) 48%, rgba(255,237,168,1) 100%);
}
.firstScreen .screenWidth {
  max-width: 100%;
  margin: 0 100px;
}
.termsDescription {
  margin-bottom: 130px;
}
.termsPage pre p {
  line-height: 24px;
}
.headerBtn {
  transform: translateY(-50%);
  width: 166px;
  height: auto;
}
.desktop .headerBtn {
  transform: translateY(-50%);
  width: 113px;
  height: 48px;
  margin: auto;
}
.headerBtn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
body .slick-prev:before, body .slick-next:before {
  color: #4D3B3A;
  font-size: 50px;
  opacity: 1;
}

body .slick-prev {
  left: -12px !important;
}
body .slick-next {
  right: 0;
}
body .slick-dots {
  bottom: -60px;
}
body .slick-prev , body .slick-next {
  top: auto;
  bottom: -19%;
}
body .slick-dots li button:before {
  font-size: 20px;
}
body .slick-dots li {
  margin: 0 10px;
}
body .slick-active .custom-dot {
  background:#8b8989;
}
body .slick-dots li {
  width: 25px;
  height: 25px;
}
body  .custom-dot  {
  background: #ddd;
  border-radius: 100%;
  text-align: center;
  width: 25px;
  height: 25px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.desktoPermission {
  margin-bottom: 130px;
}
.languageButton p, .categoryName p {
  word-break: break-word;
}
@keyframes blink {50% { color: transparent }}
.loader__dot { 
  animation: 1s blink infinite; 
  font-size: 40px; 
  line-height: 0;
}
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

@keyframes moveLeftToRight {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-10px); }
  60% { transform: translateY(-5px); }
}

.svg-move {
  animation: moveLeftToRight 1s forwards;
}

.dot-appear {
  opacity: 0;
  animation: dotAppear 1s forwards 1s;
}

@keyframes dotAppear {
  to { opacity: 1; }
}

.bounce {
  animation: bounce 1s infinite;
}

html.screenSaver body {
  overflow: hidden;
}

body.changi .select-language h2, 
body.changi .headerBtn span , 
body.changi .categoryPage .categoryName p, 
body.changi .categoryPage .heading span,
body.changi .desktoPermission h1,
body.changi .desktoPermission p,
body.changi .desktoPermission h2,
body.changi .desktoPermission h3,
body.changi .feedback h3,
body.changi .feedback h2,
body.changi .feedback p,
body.changi .thanksPage h2,
body.changi .thanksPage h3,
body.changi .waiting .headingQueue h2,
body.changi .permissionPopup h2,
body.changi .permissionPopup p,
body.changi .permissionPopup .requesLocation,
body.changi .showCustomModal h3,
body.changi .showCustomModal h2,
body.changi .showCustomModal .buttonTransparent{
  color: var(--primaryColor);
}
body.changi .select-language .language .languageButton,
body.changi .select-language .language .selectedLanguage  {
  color: var(--primaryColor) !important; 
  border-color: var(--primaryColor) !important;
}

body.changi .select-language .language .languageButton:hover,
body.changi .categoryPage .categoryName .languageButton:hover p {
  color: var(--white) !important;
}
body.changi .select-language .language svg path ,
body.changi .videoCallPageIcons .customColor svg path,
body.changi .videoIcon .customColor svg path,
body.changi .customColor svg path,
body.changi .prompt,
body.changi .audioPrompt{
  fill :  var(--primaryColor);
}
body.changi .waiting p {
  color: #5D5145;
}
body.changi .header svg path:first-child {
  fill: #6B2A7E;
}
body.changi .header {
  background-color: var(--changiHeader) !important;
}
body.changi .customBtn button, body.changi .select-language .language .languageButton:hover,  body.changi .categoryPage .languageButton:hover, body.changi .feedback button:hover,
body.changi .showCustomModal .modal button:first-child, body.changi .thanksPage button, body.changi .smallBage, body.changi .btnReload,  body.changi .defaultBtn button {
  background-color: var(--fushia) !important;
}
body.changi .customBtn button:hover, body.changi .showCustomModal .modal button:first-child:hover,  body.changi .thanksPage button:hover, body.changi .defaultBtn button:hover ,
body.changi .btnReload:hover{
  background-color: #e97ab3 !important;
}
body.changi .select-language .language .languageButton, body.changi .categoryPage .languageButton, body.changi .feedback button {
  border-color : var(--fushia) !important;
}
body.changi .waiting .queueImg svg path {
  fill: #491F53;
}
body.changi .feedback button {
  color:  var(--fushia) !important;
}
body.changi .videoCallPageIcons .customColor .svg-move svg path:nth-child(2),  body.changi .videoCallPageIcons .customColor .svg-move svg path:first-child,
body.changi .customColor .svg-move svg path {
  fill: var(--redColor);
}
body.changi .videoCallPageIcons .customColor .svg-move svg path:nth-child(3), body.changi .customColor .svg-move svg path:nth-child(3) {
  fill: var(--white);
}
body.changi .callHeader {
  background-color: var(--changiHeader) !important;
}
body.changi .feedback button:hover {
color: var(--white) !important;
}
body.changi .categoryName .selectedCat {
  border-color: var(--fushia) !important;
}
body.changi pre {
  color: var(--primaryColor) !important;
}
pre p, pre span, pre h1, pre h2, pre h3, pre h4, pre h5, pre h6,
body pre h1 span, pre h2 span, pre h3 span, pre h4 span, pre h5 span, pre h6 span
 {
  font-family: var(--custom-font-family) !important;
}
body.changi .btnWhite, body.changi .prompt {
  color: var(--primaryColor) !important;
}
body.changi .exclamationIcon svg path {
  fill: var(--primaryColor);
}
body.changi .showCustomModal svg path {
  fill:  var(--white);;
}
body.changi .showCustomModal svg circle {
  fill:  var(--changiHeader);
}
.screenHeight {
  height: 100dvh;
}
body.changi .bottomArrow svg path {
  fill: var(--changiHeader);
}
body.voncierge .defaultBtn button:hover, body.voncierge .modalBtn button:hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
body .categoryName .languageButton {
  width: 20%;
}

body .categoryName .languageButton p {
  line-height: 26px;
}
body .translationText {
  transform: translateX(-50%);
}
body .customTextFontSize {
  font-size: 24px;
}
.hide-cursor {
  cursor: none;
}
body.errorPageStyled {
  height: auto;
}
body .customUrl {
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}
body .safariUrl {
  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}
body.changi .startNewCallBtn button:hover {
  background-color: #EA4498 !important;
}
body.changi .startNewCallBtn button {
  border-color:  #EA4498 !important;
  color: var(--primaryColor) !important;
}
body.changi .bgSubmit {
  background-color: #ddd;
}
body.changi.submitDisabled .bgSubmit {
  background-color: #EA4498 !important;
}
body.changi .csatBox {
  border-color: #EA4498 !important;
}
body.changi .csatBox:checked {
  border-color: #EA449880 !important;
}
@media(max-width: 1440px) {
  body h1.notfoundHeading {
    font-size: 36px;
  }
  body .errorPage svg.face {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }
  body .errorPage button {
    font-size: 20px;
    padding: 12px 20px;
  }
  body .categoryName .categoryIcon {
    margin-bottom: 10px;
  }
  /* body .categoryName .languageButton p  {
    font-size: 18px;
  } */
}
@media screen and (min-width: 1281px) and (max-width: 1369px) { 
  body .feedbackDiv h2 {
    line-height: normal;
  }
  body .startNewCallBtn {
    margin-top: 65px;
  }
  body .feedbackDiv .feedbackIcon {
    width: 60px;
    height: 60px;
    margin: auto;
  }
  body .dynamicCsat .startNewCallBtn {
    margin-top: 0;
  }
  body .feedbackDiv,  body .feedbackDiv .iconDiv {
    margin-top: 30px;
  }
  body .thankYou {
    height: calc(100dvh - 60px);
  }
  body .iconDiv > div {
    width: 90px;
    height: 100px;
  }
  body .ratingName {
    margin-top: 10px;
  }
  .showCustomModal .modal {
    height: 370px;
    width: 350px;
  }
  .showCustomModal .modal h3 {
    font-size: 16px;
    padding: 10px 0 15px;
  }
  .showCustomModal .modal h2 , .showCustomModal .modal h3 {
    line-height: normal;
  }
  .showCustomModal .modal h2 {
    font-size: 20px;
    margin: 0;
  }
  .showCustomModal .modal img {
    width: 60px;
  }
  .showCustomModal .modal button, body .btnDiv.customBtn button, .feedback button {
    width: auto;
    height: 44px;
    font-size: 14px;
  }
  .message-text div, .send-message-form + p  {
    font-size: 14px !important;
  }
  .send-message-form button img {
    margin-right: 8px;
  }
  .videoCallPageIcons > div, .videoIcon > div {
    height: 50px;
    width: 50px;
  }
  .videoCallPage .cameraInfoIcon {
    width: auto !important;
    height: auto !important;
    top: 5px !important;
    right: 5px !important;
  }
  form.send-message-form {
    height: 52px;
  }
  .message-text {
    padding: 10px;
  }
  .customTransition {
    margin-bottom: 110px;
  }
 .languageName {
    gap: 15px;
  }
  .languageName > div{
    height: 130px;
  }
  body .language .heading h2, .categoryPage .heading span, .customTransition h1, .waiting h2, .feedback h2, body h1 {
    font-size: 24px;
    margin-top: 0;
  }
  .firstScreen h1 {
    font-size: 30px;
  }
  .firstScreen h6 {
    margin: 0;
  }
  .language .heading img {
    width: 50px;
    height:50px;
  }
  .header {
    height: 60px;
  }
  body .header .btnEffect {
    width: 80px;
  }
  body .header.desktop .btnEffect {
    width: 120px;
    height: 44px;
  } 
  body .header.desktop .btnEffect svg {
    width: 32px;
    height: 32px;
  }
  body .header.desktop .btnEffect span {
    font-size: 18px;
  }
  body .logo > div {
    background-size: 80px;
  }

  .categoryName .languageButton > div, .categoryName .selectedCat > div{
    padding: 20px 10px;
  }
  .languageName > div, .categoryName p, .terms p, body p, .customTransition p{ 
    font-size: 16px !important;
  }
  body .dynamicCsat .ratingName {
    font-size: 12px !important;
    margin-top: 10px;
  }
  body .categoryName .categoryIcon {
    margin-bottom: 0;
  }
  .categoryIcon {
    width: 50px;
    height: 50px;
  }
  .categoryName p {
    line-height: 26px;
  }
  .categoryName  {
    margin-top: 30px;
  }
  .termsBgImg {
    padding: 30px 0;
  }
  .termsBgImg h1 {
    font-size: 36px;
  }
  .btnDiv {
    height: 80px;
  }
  .feedback button {
    padding: 0 30px;
  }
  .feedback .thankYou {
    width: 700px;
  }
  .language {
    margin-bottom: 30px;
  }
  body .queueImg {
    height: 286px !important;
  }
  .queueDiv, .headingQueue {
    margin-top: 20px;
  }
  .advisorNameHeading {
    font-size: 18px;
  }
  .feedback img {
    height: 100%;
    margin: auto;
  }
  .firstScreen h6 {
    font-size: 18px;
  }
}
@media (max-width: 1368px) {
  body textarea {
    font-size: 14px !important;
  }
 }
@media(max-width: 1280px) {
  body .categoryName .languageButton {
    width: 24%;
}
  .firstScreen .screenWidth {
    max-width: 1100px;
  }
  .send-message-form button {
    padding: 5px 0;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1279px) { 
  .headerBtn img {
    width: 100%;
    height: 100%;
  }
  .firstScreen .screenWidth {
    max-width: 100%;
  }
  body .videoDiv {
    width: auto;
  }
  .feedback .thankYou {
    justify-content: center;
  }
  .feedback .iconDiv {
    margin-top: 60px;
  }
}
@media(max-width: 1025px) {
  body.videoPage.changi .arrowIconBgImg > div {
    background-color: var(--fushia) !important;
  }
  body.changi .header svg path:nth-child(2) {
    fill: #6B2A7E;
  }
  body.changi .header svg path:first-child {
    fill: var(--white);
  }
  .homeButton.headerBtn {
    right: 0;
  }
  body {
    touch-action: manipulation;
  }
  .headerBtn {
    width: 100px;
  }
  .chat-wrapper {
    width: 100%;
    bottom: 0;
    z-index: 99;
    right: 0;
    border-radius: 0;
  }
  .videoDiv,   .chat-box  {
    height: 100dvh;
  }
  .message-list {
    /* height: calc(100vh - 216px); */
    height: calc(100vh - 300px);
  }
  .send-message-form {
    margin-bottom: 0px;
  }
  .languageButton {
    transition: background-color 0.2s;
  }
  .languageButton:hover,
  .languageButton:active,
  .languageButton:focus {
    /* background-color: #EA4498; */
    color: #fff !important
  }
  body .termsPage .firstScreen {
    height: 142px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
}
@media(max-width: 1024px) {
  body.changi .mobilePermission h1, body.changi .mobilePermission p, body.changi .mobilePermission h2, body.changi .mobilePermission h3 {
    color: var(--primaryColor);
  }
  body .chromeBlockPermission.blockPermission {
    width: 300px;
    height: 300px;
    margin: auto;
  }
  body .chromeBlockPermission.blockPermission svg {
    width: 100%;
    height: 100%;
  }
  body .feedback .thankYou {
    height: calc(100vh - 250px);
    justify-content: center;
  }
  body .feedback .iconDiv {
    margin-top: 30px;
  }
  body.changi .select-language .language .languageButton,
  body.changi .select-language .language .selectedLanguage {
    height: 200px;
    width: 300px;
  }
  body .homePage .subheading {
    font-size: 24px;
  }
  body .categoryName .languageButton {
    width: 45%;
  }
  body .categoryName {
    margin: 70px 40px 0;
  }
  body .noAdvisorImg  {
    width: 90%;
  }
  body button.toggleCameraBtn {
    height: 50px !important;
    display: block;
  }
  /* #root + div {
    transform: translate(-50%, -50%);
    top: 50% !important;
    right: auto !important;
    left: 50% !important;
    height: auto;
  } */
}
@media(max-height: 900px) {
  .desktopView .screenHeight .logo {
    margin-top: 60px;
  }
  .desktopView .midSection {
    margin: 100px 0 80px;
  }
  .desktopView .bgImg {
    width: calc(100vh - 100px);
  }
}
@media(max-width: 830px) {
  body .categoryName {
    margin-bottom: 70px;
  }
}
@media (max-width: 1024px) and (max-height: 600px)  {
  /* body .firstScreen {
    height: 100dvh;
  } */
  .screenSaverImg {
    background-position: center;
  }
  .captchaDiv {
    margin: 10px 0 0;
  }
}
@media(max-height: 768px) {
  body .bgwhite {
    bottom: 15%;
    transform: translateX(-50%) scale(0.5);
  }
  .logo {
    top: 10%;
  }
} 
@media(max-height: 600px) {
  .firstScreen, .screenHeight {
    height: auto;
  }

}
@media(max-width: 980px) {
  .homeButton.headerBtn {
    right: 30px
  }
  .videoPage {
    background-color: #292828;
  }
  .headerBtn {
    width: 60px;
    height: 60px;
  }
  .headerBtn svg {
    width: 100%;
    height: 100%;
  }
  .permissionPopup svg {
    height: 100%;
    width: 100%;
  }
  body .slick-dots {
    bottom: -80px;
}
  html, body, body > div {
    height: 100%;
  }
  body .blockPermission {
    width: 300px;
    height: 300px;
    margin: auto;
  }
  .termsDescription {
    margin-bottom: 0;
    padding-bottom: 180px;
  }
}
@media(max-width: 790px) {

  body .mobilePermissionDiv,  body .mobilePermission {
    margin: 30px 70px;
  }
  body .headingPermission {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media(max-width: 768px) {
    body.changi .select-language .language .languageButton,
    body.changi .select-language .language .selectedLanguage  {
      width: 310px;
      height: 228px;
  }
}
@media(min-width: 576px) and (max-width: 767px) {
  .homePage .firstScreen, .homePage {
    height: 100%;
  }
  body .mobileScreenBgImg {
    height: calc(100% - 339px);
  }
  body .screenSaverImg {
    /* height: 100%; */
    height: calc(100vh - 339px);
  }
}
@media(max-width: 667px) {

  .logo {
    top: 5%;
  }

  body .slick-prev:before, body .slick-next:before {
    font-size: 36px;
  }
  body .slick-prev {
    left: -39px;
  }
  body .slick-dots li button:before {
    font-size: 15px;
  }
}

@media only screen and (orientation: landscape) {
  body.landscapeMode .screenSaverImg {
    height: 100%;
  }
  body.landscape.videoPage .customColor {
    width: 40px;
    height: 40px;
  }
  body.landscape.videoPage .customColor svg {
    width: 16px;
    height: 16px;
  }
  body.landscape .bottomSection {
    transition: 0.5s all ease;
  }
  body.landscape.bottomPageArrowStyle .chatBox {
    height: 100vh;
  }
  body.landscape.bottomPageArrowStyle .bottomSection {
    bottom: -51px;
  }
  body.landscape.bottomPageArrowStyle .bottomSection .arrowIcon {
    width: 20px;
    height: 6px;
    display: flex;
    justify-content: center;
  }
  body.videoPage.landscape .arrowIconBgImg > div {
    background-color: unset !important;
  }
  body.videoPage.landscape .bottomIcon {
    background-color: #292626;
    padding-top: 0;
  }
  body.landscapeMode .firstScreen .screenHeight,  body.landscapeMode .homePage {
    height: 100%;
  } 
  body.landscapeMode .firstScreen {
    height: auto;
  }
  html.screenSaver body.landscapeMode {
    overflow: auto;
    height: auto;
  }
  body.landscapeMode .mobileScreenBgImg {
    height: 500px;
    width: 500px;
    margin: 0 auto;
  }
  body.landscapeMode .bgImgDiv {
    height: unset;
  }
  .landscape .arrowIconBgImg {
    background-size: cover;
    padding: 10px 0;
  }
  body.landscapeMode {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .landscapeMode .feedback, body.landscapeMode .feedback .thankYou ,
  .landscapeMode .thanksPage,  .landscapeMode .thanksPage .thankYou{
    height: 100%;
  }
  .landscapeMode .thanksPage .thankYou{ 
    margin-top: 50px;
  }
  body.landscapeMode .feedbackDiv {
    margin: 100px 0;
  }
  .landscapeMode .thanksPage .btnThanks {
    margin-bottom: 50px;
  }
}
@media(max-width: 639px) {
  body.changi .select-language .language .languageButton,
  body.changi .select-language .language .selectedLanguage  {
    width: 46%;
    height: 90px;
}
  body .homePage .subheading {
    font-size: 16px;
  }
  body.changi .screenHeight {
    height: 100dvh;
  }
  body.voncierge .screenHeight {
    height: calc(100dvh - 60px);
  }
  body .queueImg {
    width: 300px;
    height: 200px;
  }
  body .queueImg svg {
    height: 100%;
    width: 100%;
  }
  .headerBtn {
    height: 40px;
    width: 40px;
  }
  .homeButton.headerBtn {
    right: 15px;
  }
  .message-list {
    height: calc(100vh - 140px);
  }
  .screenImg {
    background-size: 30vh;
    height: 600px;
  }
  .fullPage {
    align-items: center;
  }
  .bgwhite {
    transform: translateX(-50%) scale(1);
    bottom: 22%;
  }
  .noAdvisorImg {
    height: calc(100dvh - 60px);
    background-size: 300px;
  }
  body button.modalDarkBtn {
    height: auto !important;
    padding: 10px 14px;
  }
  body button {
    height: 46px !important;
    width: auto !important;
    border-radius: 10px !important;
  }
  body .submitBtn button, body .startNewCallBtn button {
    width: 280px !important;
  }
  body button.btnReload {
    padding: 0 12px !important;
    margin-top: 30px;
  }
  body .btnDiv {
    height: 85px;
  }
  body .mobilePaddingPermission, body .mobilePermission {
    margin: 30px 15px;
  }
}
@media(max-width: 600px) {
  
  body .videoCall.csatActive .callScreen,
  body .videoCall.csatActive,
  body .videoCall.csatActive .videoDiv {
    height: calc(100dvh - 177px) !important;
  }
  body .videoCall.csatActive {
    position: relative;
  }
  body .videoCall.csatActive .bottomSection {
    position: absolute;
  }
  .chatOpen.screensaver.videoCall.csatActive {
    z-index: 999999;
}
body .videoCall.csatActive.chatOpen .bottomSection {
  position: absolute;
  z-index: -1;
}
  body .chatBox {
    height: 100% !important ;
  }
  /* body .videoCall.csatActive .bottomSection {
    position: static;
  } */
  body .dynamicCsat .startNewCallBtn button {
    width: auto !important;
  }
  body .inputBtn button,  body .dynamicCsat .inputBtn .startNewCallBtn button {
    width: 100% !important;
  }
}
@media(max-width: 575px) {
  body .safariUrl {
    max-width: 200px;
  }
  body .termHeading {
    font-size: 24px !important;
  }
  body .firstScreen {
    /* height: calc(100vh - 40px); */
    height: 100dvh;
  }
  body .mobileScreenBgImg {
    height: calc(100% - 280px);
    margin: 10px;
  }
  body .mobilePermissionDiv,  body .mobilePermission {
    margin: 30px 15px;
  }
  body .headingPermission {
    margin-left: 15px;
    margin-right: 15px;
  }
  body.errorPageStyled h1 {
    font-size: 26px !important;
  } 
  body.errorPageStyled p {
    font-size: 20px !important
  }
  body .thanksPage .subheading {
    font-size: 20px;
  }
  body .feedback .subheading {
    line-height: 30px;
  }
  body .customTextFontSize {
    font-size: 14px;
  }
  body .categoryName .languageButton p {
    font-size: 14px;
    line-height: normal;
  }
  body .categoryName {
    margin: 20px 20px 70px;
  }
  .homeButton.headerBtn {
    right: 35px;
  }
  body .language .heading span svg {
    width: 40px;
    height: 40px;
  }
  .termsDescription {
    margin-bottom: 130px;
    padding-bottom: 0;
  }
  body .slick-dots  {
    bottom: -60px;
  }
  body .blockPermission {
    width: 210px;
    height: 210px;
  }
  .headerBtn {
    width: 32px;
    height: 32px;
  }
  .waiting h1 {
    font-size: 60px !important;
    line-height: 70px;
  }
  .errorPage h1 {
    font-size: 26px !important;
  }
  h1 {
    font-size: 20px !important;
  }
  .language {
    min-height: unset
  }
  .videoPage .arrowIconBgImg {
    background-color: #292828;
  }
  html, body, body > div {
    height: unset;
  }
  .firstScreen, .videoCall, .waitingRoomDiv {
    height: 100dvh;
  }
  .firstScreen, .videoCall, .waitingRoomDiv {
    height: 100dvh;
}
  .videoDiv, .videoCall {
    height: calc(100dvh - 100px);
    border-radius: 0 !important
  }
  .message-list {
    height: calc(100dvh - 200px);
  }
  .chat-box {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    top: 0;
    height: 100dvh;
    border-radius: unset;
    z-index: 9;
  }
  html, body, body > div {
    height: unset;
  }
  .keyboardOpen.chat-box {
    height: 70dvh;
  }
  .screenImg {
    height: 460px;
  }
  .logo {
    top: 5%;
  }
  .bgwhite {
    bottom: 15%;
  }
}
@media(max-width: 430px) {
  body .homeButton.headerBtn {
    right: 32px;
  }
  body .header .headerBackBtn {
    left: 32px;
  }
  body .homeIcon {
    margin-top: 0;
  }
}
@media(max-width: 390px) {

  body .feedbackIcon {
    width: 44px;
    height: 44px;
  }
}
@media(max-width: 375px) {
  body .mobilePermissionDiv, body .mobilePermission {
    margin: 20px 15px 0;
  }
  body .mobilePermission p {
    font-size: 15px;
  }
  .screenImg {
    height: 420px;
  }
  /* .bgImg {
    height: 230px;
  } */
}
/* @media(max-width: 360px) {
  body .ratingName {
    font-size: 10px;
  }
} */
@media(max-width: 300px) {
  .connectStyle {
    max-width: 14rem;
  }
} 
